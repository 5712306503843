/** @jsx jsx */
import Image from 'components/image';
import { graphql, useStaticQuery } from 'gatsby';
import { Col } from 'react-bootstrap';
import { Box, Container, Heading, Text, jsx } from 'theme-ui';


const HandymanDetail = () => {
    const data = useStaticQuery(graphql`
    query {
      ImageHandyman: file(relativePath: { eq: "handyman.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG])
        }
      }
      ImageHandymanApp: file(relativePath: {eq: "handymanApp.png"}) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG], quality: 100)
        }
      }
      ImageHandymanDesign: file(relativePath: {eq: "handyman-Sign.png"}) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG], quality: 100)
        }
      }
    }
  `);
    return (
        <Box as="section" id="Blog_Details" sx={styles.section}>
            <Container>
                <Col xl="9" lg="9" md="10" className='mx-auto'>
                    <Image
                        src={data.ImageHandyman.childImageSharp.gatsbyImageData}
                        loading="lazy"
                        alt="cbc"
                        className="rounded"
                    />
                </Col>
                <Col xl="7" lg="7" md="10" className="mx-auto mb-5">
                    <Heading sx={styles.title}>
                        About Handyman
                    </Heading>
                    <Text as="p" sx={styles.summary}>
                        Handyman offers a straightforward way to book services. Choose from various options and enjoy hassle-free booking. Whether you need a quick fix or a major renovation, our platform simplifies the process, allowing you to book with ease. Say goodbye to the complexities of traditional handyman services and hello to a more convenient way to handle your home repairs.
                    </Text>
                    <Heading sx={styles.title}>
                        The Challenge
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Booking handyman services can be daunting. With Handyman, our goal was to simplify this process and provide a user-friendly platform for booking home repairs. We aimed to eliminate the need for multiple calls and inquiries, offering a one-stop solution for all your handyman needs.
                    </Text>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our platform integrates seamlessly with a network of skilled professionals, ensuring that you get reliable and efficient service every time. From minor repairs to major renovations, Handyman has you covered.
                    </Text>
                    <Heading sx={styles.title}>
                        Booking Made Easy
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        With Handyman, booking your home repairs is as easy as a few clicks. Simply choose the service you need, select a convenient time, and our platform will connect you with a qualified professional. It's that simple!
                    </Text>
                </Col>
                <Col xl="9" lg="9" md="10" className='mx-auto'>
                    <Image
                        src={data.ImageHandymanDesign.childImageSharp.gatsbyImageData}
                        loading="lazy"
                        alt="Webhelp formation"
                        className=""
                    />
                </Col>
                <Col xl="7" lg="7" md="10" className="mx-auto">
                    <Heading sx={styles.title}>
                        Design Process
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our design process for Handyman began with creating wireframes to visualize the app experience. These wireframes helped us validate our ideas and ensure they aligned with the needs of users looking to book handyman services.
                    </Text>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our goal was to create a user-friendly interface that simplifies the booking process for home repairs. We focused on providing a seamless experience that allows users to easily select services, choose appointment times, and connect with qualified professionals.
                    </Text>
                </Col>
                <Col xl="7" lg="7" md="10" className="mx-auto pb-5">
                    <Heading sx={styles.title}>
                        Handyman App Development
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our goal with the Handyman app was to create a user-friendly platform that simplifies the booking process for home repairs. We focused on providing a seamless experience that allows users to easily select services, choose appointment times, and connect with qualified professionals.
                    </Text>
                    <Image
                        src={data.ImageHandymanApp.childImageSharp.gatsbyImageData}
                        loading="lazy"
                        alt="Handyman App"
                        className=""
                    />
                    <Heading sx={styles.title}>
                        Features and Functionality
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        The Handyman app offers a range of features to make booking and managing home repairs easier. Users can browse services, view service providers' profiles, schedule appointments, and track the progress of their repairs—all from the convenience of their mobile device.
                    </Text>
                </Col>
            </Container>
        </Box >
    );
};

export default HandymanDetail;

const styles = {
    section: {
        backgroundColor: '#f3f6fe',
        pt: [8, null, 12, 6, null, 10],
        pb: [null, null, null, 8, 0],
    },
    grid: {
        gap: [null, null, null, null, 12],
        display: ['flex', null, null, 'grid'],
        flexDirection: ['column-reverse', null, null, 'unset'],
        alignItems: 'center',
        gridTemplateColumns: ['1fr', null, null, null, '470px 1fr', '1fr 549px'],
    },
    illustration: {
        textAlign: 'center',
        position: 'relative',
        mt: [2, null, null, 0, 4, 0],
        img: {
            maxWidth: ['100%', null, null, null, null, ''],
        },
    },
    content: {
        marginTop: [null, null, null, null, null, -16],
        maxWidth: [null, null, null, 420, 560, 'none'],
        margin: [null, null, null, '0 auto', 'unset'],
        textAlign: ['center', null, null, null, 'left'],
        mb: 4
    },
    titlePhase: {
        color: '#ec1c23',
        fontFamily: 'headingAlt',
        fontSize: [4, null, null, 8, null, null, 14],
        fontWeight: 500,
        lineHeight: [1.33, null, 1.4, 1.2],
        letterSpacing: ['-0.5px', null, null, '-1px'],
        mb: 3,
        mt: 6,
        span: {
            backgroundSize: 'cover',
            px: 2,
        },
    },
    title: {
        color: '#09386b',
        fontFamily: 'headingAlt',
        fontSize: [4, null, null, 8, null, null, 14],
        fontWeight: 500,
        lineHeight: [1.33, null, 1.4, 1.2],
        letterSpacing: ['-0.5px', null, null, '-1px'],
        mb: 3,
        mt: 6,
        span: {
            backgroundSize: 'cover',
            px: 2,
        },
    },
    summary: {
        color: 'textSecondary',
        fontFamily: 'headingAlt',
        fontSize: ['15px', null, null, 2, '15px', 4],
        lineHeight: [1.86, null, null, null, 1.86, 1.5],
        m: [null, null, null, '20px auto 0', '15px 0 0', null, 'unset'],
    },
    list: {
        gap: '0 18px',
        fontFamily: 'headingAlt',
        gridTemplateColumns: ['repeat(2, 142px)', null, null, 'repeat(2, 200px)'],
        justifyContent: [null, null, null, 'center', 'unset'],
        listStyle: 'none',
        display: 'block',
        mt: [4, null, null, 5, 4, 5],
        p: 0,
        li: {
            fontSize: [0, 1, null, 2, '15px', 2],
            fontWeight: 600,
            alignItems: 'center',
            color: 'textSecondary',
            fontFamily: 'headingAlt',
            display: 'flex',
            lineHeight: [2.81, null, null, null, 2.2, 2.81],
        },
    },
};
